<template>
  <div>
    <SpinnerLoader :loading="requestExecuting" :float-in-corner="true" />
    <div class="d-flex flex-wrap w-100 gap-3 align-items-center" style="padding: 75px 16px 0">
      <h5 class="mb-0 align-self-center">{{ $t('table.matchingImport') }}</h5>
      <MultiSelect
        v-model="selects.channel"
        style="width: 250px"
        class="multiselect-sm"
        :options="channels"
        :placeholder="$t('channelModal.channel')"
        label="name"
        track-by="id"
        :allow-empty="false"
        @input="loadBindedGRPs(true)"
      />
      <datepicker-with-side-buttons
        v-model="selects.day"
        style="width: 250px"
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short' }"
        @input="loadBindedGRPs(true)"
      />
      <b-button
        v-if="$checkPermissions('grp_fact.upload')"
        v-b-modal.import-fact-ratings-modal
        size="sm"
        :title="$t('broadcastSideBar.uploadFact')"
        :disabled="!!requestExecuting"
      >
        <BIconFileEarmarkArrowUp />
      </b-button>
      <b-button size="sm" :disabled="!isSuccess || !!requestExecuting" @click="toggleFiltersRow">
        <b-icon :icon="selects.advertiser && selects.advertiser.id ? 'funnel-fill' : 'funnel'"></b-icon>
        {{ $t('table.filter') }}
      </b-button>
      <b-button v-if="selects.channel && selects.day" size="sm" :disabled="!isSuccess || !!requestExecuting" @click="bindFactByExternalId">
        {{ $t('table.bindByExternalId') }}
      </b-button>
      <div class="custom-control custom-switch mr-2">
        <input
          id="custom-switch-block-scope"
          v-model="bindByBlockScope"
          type="checkbox"
          class="custom-control-input"
          :disabled="!isSuccess || !!requestExecuting"
        />
        <label class="custom-control-label" for="custom-switch-block-scope">
          <abbr :title="`${$t('table.byBlocksOrWithout')} \n\n${$t('table.importDeltaExplanation')}`">{{ $t('table.byBlocks') }}</abbr>
        </label>
      </div>
      <b-button v-if="selects.channel && selects.day" size="sm" :disabled="!isSuccess || !!requestExecuting" @click="fullFactRebind">
        {{ $t('table.fullRebind') }}
      </b-button>
      <template v-if="isSuccess">
        <b-form-checkbox id="not-matched-checkbox-main" v-model="isNotMatchedOnly" class="d-none d-1700p-block">
          {{ $t('table.showOnlyUnmatched') }}
        </b-form-checkbox>
        <b-button variant="outline-danger" size="sm" class="d-none d-1700p-block" :disabled="exporting" @click="exportSpots()">
          {{ exporting ? $t('table.exporting') : $t('table.export') }}
        </b-button>
      </template>
      <div id="additional-filters" class="w-full gap-3" :class="[showAdditionalFilter ? 'd-flex' : 'd-none']">
        <MultiSelect
          v-model="selects.advertiser"
          class="multiselect-sm w-250px"
          :options="advertisersList || []"
          :placeholder="$t('table.selectAdvertiser')"
          label="name"
          track-by="id"
          @input="selectedAdvertiser"
        />
        <MultiSelect
          v-model="selects.brand"
          class="multiselect-sm w-250px"
          :options="brandsList || []"
          :placeholder="$t('table.selectGroup')"
          label="name"
          track-by="id"
          :disabled="!(selects.advertiser && selects.advertiser.id)"
          @input="loadBindedGRPs(true)"
        />
      </div>
    </div>

    <div
      v-if="bindFactGrpsStatus != ''"
      class="d-flex w-100 gap-3 ws-nowrap text-overflow-ellipsis small position-relative"
      :class="{ 'with-queue': queueSelect.options.length > 0 }"
      style="padding: 16px 16px 0"
    >
      <div
        id="leftMatchingTable__wrapper"
        class="table-responsive table-matching-col mb-0"
        :class="{ smaller: showAdditionalFilter }"
        :style="{ width: isBaseGRPColsVisible || isPlacementTypeColVisible ? '60%' : '50%' }"
      >
        <div class="d-flex gap-3 sides-headers">
          <h6>
            {{ $t('table.bookingData') }}:
            <span v-if="isSuccess && bindFactGrps.channel && bindFactGrps.day"> {{ bindFactGrps.channel.name }} ({{ bindFactGrps.day | convertDate }}) </span>
          </h6>
        </div>

        <SpinnerLoader v-show="bindFactGrpsStatusCode !== 404" :loading="bindFactGrpsStatus" />
        <template v-if="bindFactGrpsStatus === 'error' && bindFactGrpsStatusCode === 404">
          <h5 class="my-3">{{ $t('table.noImportDataForThisDay') }}</h5>
          <b-button v-if="$checkPermissions('grp_fact.upload')" v-b-modal.import-fact-ratings-modal class="mb-3">
            {{ $t('broadcastSideBar.uploadFact') }}
          </b-button>
        </template>
        <template v-if="isSuccess && bindFactGrps && bindFactGrps.status === 0">
          <div class="d-flex align-items-center w-full mt-2">
            <b-spinner class="mr-3"></b-spinner>
            <p class="my-auto">{{ $t('table.importInQueue') }}...</p>
          </div>
        </template>
        <template v-else-if="isSuccess">
          <table id="tableLeft" class="table b-table table-sm b-table-selectable b-table-select-single sticky-headers">
            <thead>
              <tr>
                <th role="columnheader" scope="col" aria-colindex="1">
                  <div class="resizable resizable_date">
                    <button
                      type="button"
                      class="mr-1 btn-transparent"
                      :title="$t('table.customizeColumns')"
                      @click.stop="$refs.matchingFactCustomizeColumns.open($event, {})"
                    >
                      <BIconLayoutThreeColumns />
                    </button>
                    {{ $t('table.day') }}
                  </div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="1">
                  <div class="resizable resizable_time">
                    {{ $t('table.time') }}
                  </div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="1">№</th>
                <th v-if="isIDColVisible" role="columnheader" scope="col" aria-colindex="1">
                  <abbr :title="$t('table.commercialID')">ID</abbr>
                </th>
                <th role="columnheader" scope="col" aria-colindex="1">
                  <div class="resizable resizable_name">
                    <button
                      type="button"
                      class="mr-1 btn-transparent"
                      :title="filters.plan.selectedCommercialName.length > 0 ? $t('table.filterApplied') : $t('table.clickToSetFilter')"
                      @click.stop="openFilterMenu('name', filters.plan.commercialsNameList, $event)"
                    >
                      <b-icon :icon="filters.plan.selectedCommercialName.length > 0 ? 'funnel-fill' : 'funnel'"></b-icon>
                    </button>
                    {{ $t('table.spotName') }}
                  </div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="1">
                  <div class="resizable resizable_length">
                    {{ $t('table.durationShort') }}
                  </div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="1">
                  <div class="resizable resizable_position">
                    {{ $t('table.position') }}
                  </div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="1">
                  <div class="resizable resizable_external">
                    <button
                      type="button"
                      class="mr-1 btn-transparent"
                      :title="filters.plan.selectedExternalId.length > 0 ? $t('table.filterApplied') : $t('table.clickToSetFilter')"
                      @click.stop="openFilterMenu('extId', filters.plan.externalIdsList, $event)"
                    >
                      <b-icon :icon="filters.plan.selectedExternalId.length > 0 ? 'funnel-fill' : 'funnel'"></b-icon>
                    </button>
                    {{ $t('table.externalId') }}
                  </div>
                </th>
                <th v-if="isPlacementTypeColVisible" role="columnheader" scope="col" aria-colindex="1">
                  <div class="resizable resizable_type">
                    {{ $t('table.type') }}
                  </div>
                </th>
                <th v-if="isBaseGRPColsVisible" role="columnheader" scope="col" aria-colindex="1">
                  <abbr :title="`${$t('reports.baseTA')} GRP (${baseTAName})`"> GRP </abbr>
                </th>
                <th v-if="isBaseGRPColsVisible" role="columnheader" scope="col" aria-colindex="1">
                  <abbr :title="`${$t('reports.baseTA')} wGRP (${baseTAName})`"> wGRP </abbr>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in bindFactGrpsPlan"
                :id="`tableLeft__row_${item.spot_id}`"
                :key="item.spot_id"
                :class="rowLeftClass(item)"
                :aria-selected="item.selectedRow ? true : false"
                @click="onRowLeftSelected(item)"
                @contextmenu.prevent="leftContextMenu(item, $event)"
              >
                <td aria-colindex="1" role="cell">
                  {{ item.broadcast_day | convertDate }}
                </td>
                <td aria-colindex="1" role="cell">
                  {{ sec2time(item.spot_time) }}
                </td>
                <td aria-colindex="1" role="cell">
                  {{ item.spot_number_in_block }}
                </td>
                <td v-if="isIDColVisible" aria-colindex="1" role="cell">
                  {{ item.commercial_id }}
                </td>
                <td aria-colindex="1" role="cell" :title="item.name" name="resizable_name">
                  {{ item.name }}
                </td>
                <td aria-colindex="1" role="cell">
                  {{ item.spot_duration }}
                </td>
                <td aria-colindex="1" role="cell" :class="{ 'bg-position-warning': item.not_equal_position }">
                  {{ item.position }}
                </td>
                <td aria-colindex="1" role="cell">
                  {{ item.external_id }}
                </td>
                <td v-if="isPlacementTypeColVisible" aria-colindex="1" role="cell" class="resizable_type">
                  {{ item.placement_type_name }}
                </td>
                <td v-if="isBaseGRPColsVisible" aria-colindex="1" role="cell">
                  {{ item.base_target_audience_grp }}
                </td>
                <td v-if="isBaseGRPColsVisible" aria-colindex="1" role="cell">
                  {{ item.base_target_audience_wgrp }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </div>

      <div id="rightMatchingTable__wrapper" class="table-responsive w-50 table-matching-col mb-0" :class="{ smaller: showAdditionalFilter }">
        <div class="d-flex gap-3 align-items-baseline sides-headers">
          <h6>{{ $t('table.importData') }}</h6>
          <template v-if="isSuccess">
            <b-form-checkbox id="not-matched-checkbox" v-model="isNotMatchedOnly" class="d-1700p-none">{{ $t('table.showOnlyUnmatched') }}</b-form-checkbox>
            <button class="btn btn-sm btn-link p-0 m-0 border-0 d-1700p-none" :disabled="exporting" @click="exportSpots()">
              {{ exporting ? $t('table.exporting') : $t('table.export') }}
            </button>
          </template>
        </div>

        <SpinnerLoader v-show="bindFactGrpsStatusCode !== 404" :loading="bindFactGrpsStatus" />
        <template v-if="isSuccess && bindFactGrps && bindFactGrps.status === 1">
          <table id="tableRight" class="table b-table table-sm b-table-selectable b-table-select-single sticky-headers">
            <thead>
              <tr>
                <th role="columnheader" scope="col" aria-colindex="1">
                  <div class="resizable resizable_date">
                    {{ $t('table.day') }}
                  </div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="1">
                  <div class="resizable resizable_time">
                    {{ $t('table.time') }}
                  </div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="1">№</th>
                <th role="columnheader" scope="col" aria-colindex="1">
                  <div class="resizable resizable_name">
                    <button
                      type="button"
                      class="mr-1 btn-transparent"
                      :title="filters.fact.selectedCommercialName.length > 0 ? $t('table.filterApplied') : $t('table.clickToSetFilter')"
                      @click.stop="openFilterMenu('nameFact', filters.fact.commercialsNameList, $event)"
                    >
                      <b-icon :icon="filters.fact.selectedCommercialName.length > 0 ? 'funnel-fill' : 'funnel'"></b-icon>
                    </button>
                    {{ $t('table.spotName') }}
                  </div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="1">
                  <div class="resizable resizable_length">
                    {{ $t('table.durationShort') }}
                  </div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="1">
                  <div class="resizable resizable_external">
                    <button
                      type="button"
                      class="mr-1 btn-transparent"
                      :title="filters.fact.selectedExternalId.length > 0 ? $t('table.filterApplied') : $t('table.clickToSetFilter')"
                      @click.stop="openFilterMenu('extIdFact', filters.fact.externalIdsList, $event)"
                    >
                      <b-icon :icon="filters.fact.selectedExternalId.length > 0 ? 'funnel-fill' : 'funnel'"></b-icon>
                    </button>
                    {{ $t('table.externalId') }}
                  </div>
                </th>
                <th v-if="isBaseGRPColsVisible" role="columnheader" scope="col" aria-colindex="1">
                  <abbr :title="`${$t('reports.baseTA')} GRP (${baseTAName})`"> GRP </abbr>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in bindFactGrpsFact"
                :id="`tableRight__row_${item.id}`"
                :key="item.id"
                :class="rowRightClass(item)"
                :aria-selected="item.selectedRow ? true : false"
                @click="onRowRightSelected(item)"
              >
                <!-- @dblclick="checkForUnmatched" -->
                <td aria-colindex="1" role="cell">
                  {{ item.broadcast_day | convertDate }}
                </td>
                <td aria-colindex="1" role="cell">
                  {{ sec2time(item.start_interval) }}
                </td>
                <td>{{ item.slot_position }}</td>
                <td aria-colindex="1" role="cell" :title="item.description" name="resizable_name">
                  {{ item.description }}
                </td>
                <td aria-colindex="1" role="cell">
                  {{ item.length }}
                </td>
                <td aria-colindex="1" role="cell">
                  {{ item.external_id }}
                </td>
                <td v-if="isBaseGRPColsVisible" aria-colindex="1" role="cell">
                  {{ item.base_target_audience_grp }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </div>
    </div>
    <div
      v-if="queueSelect.options.length > 0"
      class="d-flex px-3 py-2 overflow-auto position-fixed"
      style="left: 0; bottom: 0; white-space: nowrap; max-width: calc(100vw - 110px)"
    >
      <b-button
        v-for="opt in queueSelect.options"
        :key="opt.id"
        size="sm"
        :variant="$route.query.channel_id == opt.channel_id && $route.query.date === opt.broadcast_day ? 'primary' : 'link'"
        type="button"
        @click="changeCouple(opt)"
      >
        <b-spinner v-if="opt.status === 0" small class="mr-2"></b-spinner>
        <div class="d-inline-block">
          {{ opt.name }}
        </div>
      </b-button>
    </div>

    <!--MODAL CONFIRM UPDATE-->
    <b-modal
      id="confirm-update-matched"
      ref="confirm-update-matched"
      :title="getModalTitle()"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      auto-focus-button="ok"
      @ok="confirmMatch"
    >
      <p class="text-danger">
        {{ selectedLeft ? `${selectedLeft.name} (${selectedLeft.spot_duration})` : '' }}, {{ $t('table.externalId') }}:
        {{ selectedLeft.external_id ? selectedLeft.external_id : $t('table.no') }}<br />
        {{ selectedFactRow ? `${selectedFactRow.description} (${selectedFactRow.length})` : '' }}, {{ $t('table.externalId') }}:
        {{ selectedFactRow.external_id ? selectedFactRow.external_id : $t('table.no') }}
      </p>
      <template v-if="isExternalIdConflict">
        <b-form-checkbox id="checkbox-rewrite-extId" v-model="rewriteExternalId" name="checkbox-rewrite-extId" @input="checkAfterRewriteExternalId">
          {{ $t('table.rewriteExtId') }}
        </b-form-checkbox>
        <b-form-checkbox id="checkbox-reconnectSimilar" v-model="reconnectSimilar" name="checkbox-reconnectSimilar" :disabled="!rewriteExternalId">
          {{ $t('table.reconnectSimilar') }}
        </b-form-checkbox>
      </template>
      <template v-else-if="!(isLengthConflict && isExternalIdConflict)">
        <b-form-checkbox id="checkbox-reconnectSimilar-2" v-model="reconnectSimilar" name="checkbox-reconnectSimilar-2" @input="checkAfterReconnectSimilar">
          {{ $t('table.reconnectSimilar') }}
        </b-form-checkbox>
      </template>
      <p class="my-2">
        {{ $t('table.areYouSure') }}
      </p>
    </b-modal>
    <!--MODAL CONFIRM UPDATE-->
    <!--MODAL CONFIRM UNBIND ALL SPOTS-->
    <b-modal
      id="confirm-unbind-all-spots"
      :title="$t('table.unbindAllSpotsInDay')"
      size="sm"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      auto-focus-button="ok"
      lazy
      @ok="unbindAllSpots"
    >
      <p class="my-2">
        {{ $t('table.areYouSure') }}
      </p>
    </b-modal>
    <!--MODAL CONFIRM UNBIND ALL SPOTS-->
    <!--MODAL CONFIRM UNBIND ALL SIMILAR SPOTS-->
    <b-modal
      id="confirm-unbind-all-similar-spots"
      :title="$t('table.unbindAllSimilarSpots')"
      size="sm"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      cancel-variant="outline-danger"
      auto-focus-button="ok"
      lazy
      @ok="unbindAllSimilarSpotsFunction"
    >
      <p class="my-2">
        {{ $t('table.areYouSure') }}
      </p>
    </b-modal>
    <!--MODAL CONFIRM UNBIND ALL SIMILAR SPOTS-->

    <ModalImportFactRatings :channel-id="selects.channel ? selects.channel.id : undefined" :selected-day="selects.day" @updatePage="loadBindedGRPs" />

    <!-- FLOAT BTN -->
    <button
      class="btn btn-danger btn-sm position-absolute float-btn-marketing"
      @click="$router.push({ name: 'marketingGrid', query: { date: date, channel_id: channel_id } })"
    >
      {{ $t('table.marketing') }}
    </button>

    <ContextMenu ref="menu">
      <template slot-scope="{ contextData }">
        <ContextMenuItem v-if="canEditCommercial" @click.native="openEditCommercialModal(contextData)">
          {{ $t('table.updateNameIdentification') }}
        </ContextMenuItem>
        <template v-if="contextData && contextData.fact_spot_id">
          <ContextMenuItem @click.native="unbindSpotsFromMenu()">
            {{ $t('table.unbindSpots') }}
          </ContextMenuItem>
          <ContextMenuItem @click.native="$bvModal.show('confirm-unbind-all-similar-spots')">
            {{ $t('table.unbindAllSimilarSpots') }}
          </ContextMenuItem>
        </template>
        <ContextMenuItem v-if="canBindUnbind" @click.native="$bvModal.show('confirm-unbind-all-spots')">
          {{ $t('table.unbindAllSpots') }}
        </ContextMenuItem>
      </template>
    </ContextMenu>

    <ContextMenu ref="filterMenu">
      <template v-if="contextData && contextData.options" slot-scope="{ contextData }">
        <ContextMenuItem class="border-bottom border-secondary" @click.native="resetFilter(contextData.colName)">
          {{ $t('table.all') }}
        </ContextMenuItem>
        <ContextMenuItem class="border-bottom border-secondary">
          <b-input-group size="sm">
            <b-form-input v-model="contextData.query" size="sm" :placeholder="$t('search.search')"></b-form-input>
            <b-input-group-append>
              <b-button size="sm" :title="$t('table.clear')" @click="contextData.query = ''">
                <b-icon icon="x"></b-icon>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </ContextMenuItem>
        <ContextMenuItem
          v-for="opt in contextData.options.filter((e) => (contextData.query ? String(e).toLowerCase().includes(contextData.query.toLowerCase()) : e))"
          :key="opt"
          :class="ifSelectedFilter(opt, contextData.colName) ? 'bg-secondary text-white' : ''"
          @click.native="filterTable(opt, contextData.colName)"
        >
          {{ opt }}
        </ContextMenuItem>
      </template>
    </ContextMenu>

    <ContextMenu ref="matchingFactCustomizeColumns">
      <ContextMenuItem @click.native="showOrHideCol('id')"> <b-icon :icon="isIDColVisible ? 'eye' : 'eye-slash'" /> ID </ContextMenuItem>
      <ContextMenuItem @click.native="showOrHideCol('placement_type_name')">
        <b-icon :icon="isPlacementTypeColVisible ? 'eye' : 'eye-slash'" /> {{ $t('table.placementType') }}
      </ContextMenuItem>
      <ContextMenuItem @click.native="showOrHideCol('base_target_audience')">
        <b-icon :icon="isBaseGRPColsVisible ? 'eye' : 'eye-slash'" /> {{ $t('reports.baseTA') }} GRP/wGRP
      </ContextMenuItem>
    </ContextMenu>

    <ModalCommercial
      :commercial="currentCommercial"
      :modal-name="modalName"
      :is-open-modal="isOpenModal"
      :is-one-day="true"
      :can-edit="canEditCommercial"
      @update="loadBindedGRPs()"
      @isOpenModal="isOpenModal = null"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import errorsHandler from '@/utils/errorsHandler';
import SpinnerLoader from '@/components/SpinnerLoader';
import timeConvertHelpers from '@/mixins/timeConvertHelpers';
import ModalCommercial from '@/components/Commercials/ModalCommercial';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import convertDate from '@/filters/convertDate';
import downloadFileHandler from '@/mixins/downloadFileHandler';
import MultiSelect from '@/components/MultiSelect';
import { BInputGroupAppend, BIconLayoutThreeColumns, BIconFileEarmarkArrowUp } from 'bootstrap-vue';
import DatepickerWithSideButtons from '@/components/DatepickerWithSideButtons.vue';
import { BSpinner } from 'bootstrap-vue';

export default {
  components: {
    SpinnerLoader,
    ModalCommercial,
    ContextMenu,
    ContextMenuItem,
    MultiSelect,
    DatepickerWithSideButtons,
    BInputGroupAppend,
    BIconLayoutThreeColumns,
    ModalImportFactRatings: () => import('@/components/Marketing/ModalImportFactRatings'),
    BSpinner,
    BIconFileEarmarkArrowUp,
  },
  filters: { convertDate },
  mixins: [timeConvertHelpers, downloadFileHandler],
  props: {
    date: { type: String, default: undefined },
    channel_id: { type: [String, Number], default: undefined },
    advertiser_id: { type: [String, Number], default: undefined },
    brand_id: { type: [String, Number], default: undefined },
    show_filters: { type: String, default: undefined },
    queue: { type: String, default: undefined },
  },
  data() {
    return {
      isNotMatchedOnly: false,
      selectedLeft: '',
      selectedRight: '',
      selectedFactRow: '',
      isExternalIdConflict: false,
      isNeedToForce: false,
      unbindAllSimilarSpots: false,
      rewriteExternalId: true,
      reconnectSimilar: true,
      isLengthConflict: false,
      //for commercial modal
      currentCommercial: null,
      modalName: null,
      isOpenModal: null,
      //
      filters: {
        plan: {
          externalIdsList: [],
          commercialsNameList: [],
          selectedExternalId: [],
          selectedCommercialName: [],
        },
        fact: {
          externalIdsList: [],
          commercialsNameList: [],
          selectedExternalId: [],
          selectedCommercialName: [],
        },
      },
      clicksRight: 0,
      timerRight: null,
      clicksLeft: 0,
      timerLeft: null,
      exporting: false,

      selects: {
        day: '',
        channel: '',
        advertiser: '',
        brand: '',
      },
      queueSelect: {
        options: [],
        selected: null,
      },

      requestExecuting: '',
      bindByBlockScope: true,

      matchingFactLeftHiddenCols: [],
      showAdditionalFilter: false,

      checkStatuses: null,
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      bindFactGrps: 'getBindFactGrps',
      bindFactGrpsStatus: 'getBindFactGrpsStatus',
      bindFactGrpsStatusCode: 'getBindFactGrpsStatusCode',
      channels: 'getChannel',
      advertisersList: 'getAdvertisersListWithAll',
      brandsList: 'getBrandsListWithAll',
    }),
    canBindUnbind() {
      return this.$checkPermissions('grp_fact.bind', 'grp_fact.unbind');
    },
    canEditCommercial() {
      return this.$checkPermissions('commercial.update');
    },
    bindFactGrpsPlan() {
      return (
        this.bindFactGrps?.plan?.filter(
          (el) =>
            (this.isNotMatchedOnly ? !el.fact_spot_id : el) &&
            (this.filters.plan.selectedCommercialName.length > 0 ? this.filters.plan.selectedCommercialName.includes(el.name) : el) &&
            (this.filters.plan.selectedExternalId.length > 0 ? this.filters.plan.selectedExternalId.includes(el.external_id) : el)
        ) || []
      );
    },
    bindFactGrpsFact() {
      return (
        this.bindFactGrps?.fact?.filter((el) =>
          this.isNotMatchedOnly
            ? !el.spot_id
            : el &&
              (this.filters.fact.selectedCommercialName.length > 0 ? this.filters.fact.selectedCommercialName.includes(el.description) : el) &&
              (this.filters.fact.selectedExternalId.length > 0 ? this.filters.fact.selectedExternalId.includes(el.external_id) : el)
        ) || []
      );
    },
    isSuccess() {
      return this.bindFactGrps && this.bindFactGrpsStatus === 'success';
    },
    baseTAName() {
      return this.bindFactGrpsStatus === 'success' && this.bindFactGrps?.channel?.base_target_audience_name
        ? this.bindFactGrps.channel.base_target_audience_name
        : undefined;
    },
    isIDColVisible() {
      return !this.matchingFactLeftHiddenCols.includes('id');
    },
    isPlacementTypeColVisible() {
      return !this.matchingFactLeftHiddenCols.includes('placement_type_name');
    },
    isBaseGRPColsVisible() {
      return !this.matchingFactLeftHiddenCols.includes('base_target_audience');
    },
    optionalFieldsList() {
      let arr = [];
      if (!this.matchingFactLeftHiddenCols.includes('placement_type_name')) arr.push('placement_type_name');
      if (!this.matchingFactLeftHiddenCols.includes('base_target_audience')) arr.push('base_target_audience');
      return arr.toString();
    },
  },
  watch: {
    bindFactGrps() {
      if (this.bindFactGrps) {
        // calc spot number in block, it's time
        let spotInBlockCounter = 1;
        let prevBlockId = null;
        let prevSpotTime = null;

        for (let el in this.bindFactGrps['plan']) {
          let block_id = this.bindFactGrps['plan'][el].block_id;
          let block_start_interval = this.bindFactGrps['plan'][el].start_interval;
          let spot_duration = this.bindFactGrps['plan'][el].spot_duration;
          let spot = this.bindFactGrps['plan'][el];

          //check for difference between plan and fact premium position
          if (spot.position && spot.fact_spot_id) {
            const couple_fact_spot = this.bindFactGrps['fact'].find((s) => s.id === spot.fact_spot_id);
            if (couple_fact_spot) {
              this.checkForDifferentSpotPremiumPosition(spot, couple_fact_spot);
            }
          }
          // end of check for difference

          if (prevBlockId === null) {
            // for the first time
            prevBlockId = block_id;
            prevSpotTime = block_start_interval;
          } else if (prevBlockId === block_id) {
            // if block_id doesn't changed
            spotInBlockCounter += 1;
            prevSpotTime = prevSpotTime + spot_duration;
          } else if (prevBlockId !== block_id) {
            // if block_id changed
            spotInBlockCounter = 1; // reset counter
            prevBlockId = this.bindFactGrps['plan'][el].block_id;
            prevSpotTime = block_start_interval;
            this.bindFactGrps['plan'][el].first_in_block = true; // to add border-top between blocks
          }
          this.bindFactGrps['plan'][el].spot_number_in_block = spotInBlockCounter;
          this.bindFactGrps['plan'][el].spot_time = prevSpotTime;
        }

        //add borders between blocks in fact side
        let prevBlockIdFactSide = null;
        for (let el in this.bindFactGrps['fact']) {
          let block_number = this.bindFactGrps['fact'][el].block_number;

          if (prevBlockIdFactSide === null) {
            // for the first time
            prevBlockIdFactSide = block_number;
          } else if (prevBlockIdFactSide !== block_number) {
            // if block_id changed
            prevBlockIdFactSide = this.bindFactGrps['fact'][el].block_number;
            this.bindFactGrps['fact'][el].first_in_block = true; // to add border-top between blocks
          }
        }

        // if delta exists - turn off bind by blocks toggle
        if (this.bindFactGrps.time_delta) this.bindByBlockScope = false;
        else this.bindByBlockScope = true;
      }
    },
  },
  created() {
    document.title = this.$i18n.t('table.matchingImport') + ' – OpenMediaLogic';
  },
  mounted() {
    this.restoreProps();
  },
  destroyed() {
    this.deleteImportChecker();
    this.$store.commit('clearBindFactGrps');
    this.$store.commit('clearBrandsList');
    this.$store.commit('clearAdvertisersList');
    this.$store.commit('clearAgenciesList');
  },
  methods: {
    async restoreProps() {
      await Promise.all([this.$store.dispatch('GET_CHANNEL', { per_page: 1000 }), this.$store.dispatch('GET_ADVERTISERS', { per_page: 2000 })]);
      if (this.date && this.channel_id && /\d{4}-\d{2}-\d{2}/.test(this.date) && /^\d+$/.test(this.channel_id)) {
        this.$store.commit('setBindFactGRPRequest');
        this.selects.day = this.date;
        this.selects.channel = this.channels.find((c) => c.id === +this.channel_id);
        if (this.queue) {
          this.queueSelect.options = JSON.parse(this.queue);
          this.queueSelect.selected = this.queueSelect.options.find((o) => +o.channel_id === this.selects.channel?.id && o.broadcast_day === this.selects.day);
        }
        if (this.advertiser_id && /^\d+$/.test(this.advertiser_id)) {
          this.selects.advertiser = this.advertisersList.find((a) => a.id === +this.advertiser_id) || '';
          if (this.selects.advertiser?.id) {
            await this.loadBrandsList();
            if (this.brand_id && /^\d+$/.test(this.brand_id)) {
              this.selects.brand = this.brandsList.find((a) => a.id === +this.brand_id) || '';
            }
          }
        }
      }
      if (this.show_filters === 'true') this.showAdditionalFilter = true;
      //restore user shown\hidden cols
      if (localStorage.getItem('matchingFactLeftHiddenCols')) {
        try {
          const userCols = localStorage.getItem('matchingFactLeftHiddenCols').split(',');
          if (userCols && this.matchingFactLeftHiddenCols.toString() !== userCols.toString()) {
            this.matchingFactLeftHiddenCols = userCols;
          }
        } catch (e) {
          localStorage.removeItem('matchingFactLeftHiddenCols');
        }
      }
      await this.loadBindedGRPs(true);
      if (!this.checkStatuses && this.queueSelect.options.length > 0) this.installImportChecker(); // check for update every 4s
      if (this.queueSelect.options.length > 0) {
        this.checkStatusesInterval(); // immediate update statuses
      }
    },

    async loadBindedGRPs(with_loader, queryProps) {
      if (queryProps?.date && queryProps?.channel) {
        // from import fact modal to go to the imported channel+day
        this.selects.day = queryProps.date;
        this.selects.channel = queryProps.channel;
      }
      if (!(this.selects.day && this.selects.channel?.id)) return;
      if (queryProps?.queue) {
        this.queueSelect.options = JSON.parse(queryProps.queue);
        this.queueSelect.selected = this.queueSelect.options.find((o) => o.channel_id === this.selects.channel?.id && o.broadcast_day === this.selects.day);
      }

      this.requestExecuting = 'loading';
      if (with_loader) this.$store.commit('setBindFactGRPRequest'); // for loader
      await this.$store.dispatch('GET_BIND_FACT_GRP_ID', {
        date: this.selects.day,
        channel_id: this.selects.channel?.id,
        advertiser_id: this.selects.advertiser?.id || undefined,
        brand_id: this.selects.brand?.id || undefined,
        optional_fields: this.optionalFieldsList || undefined,
      });
      if (this.bindFactGrps && this.bindFactGrpsStatus === 'success') {
        //get unique fields for filters
        this.filters.plan.commercialsNameList = [...new Set(this.bindFactGrps.plan.map((item) => item.name))].sort();
        this.filters.fact.commercialsNameList = [...new Set(this.bindFactGrps.fact.map((item) => item.description))].sort();
        this.filters.plan.externalIdsList = [...new Set(this.bindFactGrps.plan.map((item) => item.external_id))].sort();
        this.filters.fact.externalIdsList = [...new Set(this.bindFactGrps.fact.map((item) => item.external_id))].sort();
        if (!this.checkStatuses && this.bindFactGrps.status === 0) this.installImportChecker();
      }
      this.requestExecuting = '';
      this.updateQuery();
    },

    installImportChecker() {
      this.checkStatuses = setInterval(this.checkStatusesInterval, 4000);
    },

    deleteImportChecker() {
      if (this.checkStatuses) {
        clearInterval(this.checkStatuses);
        this.checkStatuses = null;
      }
    },

    updateQuery() {
      setTimeout(() => {
        if (
          this.$route.query.date != this.selects.day ||
          this.$route.query.channel_id != this.selects.channel?.id ||
          this.$route.query.advertiser_id != this.selects.advertiser?.id ||
          this.$route.query.brand_id != this.selects.brand?.id ||
          this.$route.query.show_filters != this.showAdditionalFilter.toString()
        ) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              date: this.selects.day,
              channel_id: this.selects.channel?.id,
              advertiser_id: this.selects.advertiser?.id,
              brand_id: this.selects.brand?.id,
              show_filters: this.showAdditionalFilter.toString(),
            },
          });
        }
      }, 50);
    },

    getModalTitle() {
      if (this.isExternalIdConflict && this.isLengthConflict)
        return this.$i18n.t('table.spotsDifferentExternalId') + ' & ' + this.$i18n.t('table.spotsLengthDifference', [2]);
      else if (this.isExternalIdConflict) return this.$i18n.t('table.spotsDifferentExternalId');
      else if (this.isLengthConflict) return this.$i18n.t('table.spotsLengthDifference', [2]);
      else return this.$i18n.t('table.updateMatchSpots');
    },

    checkAfterRewriteExternalId() {
      if (!this.rewriteExternalId) {
        this.reconnectSimilar = false;
      }
    },

    checkAfterReconnectSimilar() {
      if (!this.reconnectSimilar && this.isExternalIdConflict && !this.isLengthConflict) this.isNeedToForce = false;
      else this.isNeedToForce = true;
    },
    onRowLeftSelected(item) {
      if (this.canBindUnbind) {
        this.clicksLeft++;
        if (this.clicksLeft === 1) {
          // on single click
          this.timerLeft = setTimeout(() => {
            //Set selected item from left (on system) table
            this.selectingLeft(item, true);
            //
            this.clicksLeft = 0;
          }, 300);
        } else {
          //dblick simulation
          clearTimeout(this.timerLeft);
          this.selectingLeft(item, false);
          this.syncScroll(item);
          //
          this.clicksLeft = 0;
        }
      }
    },
    onRowRightSelected(item) {
      if (this.canBindUnbind) {
        this.clicksRight++;
        if (this.clicksRight === 1) {
          // on one click
          this.timerRight = setTimeout(() => {
            //Set selected item from right (on import) table
            this.selectingRight(item, true);
            // clear click count
            this.clicksRight = 0;
          }, 300);
        } else {
          // dblclick simulation
          clearTimeout(this.timerRight);
          this.clicksRight = 0;
          //Set selected item from right (on import) table
          this.selectingRight(item, false);
          this.checkForUnmatched(item);
        }
      }
    },

    selectingLeft(item, toClear) {
      if (this.selectedLeft) {
        delete this.selectedLeft.selectedRow;
        if (this.selectedLeft.spot_id === item.spot_id && toClear) {
          this.selectedLeft = '';
          return;
        }
      }
      this.selectedLeft = item || '';
      if (this.selectedLeft) this.selectedLeft.selectedRow = true;
    },

    selectingRight(item, toClear) {
      //Set selected item from right (on import) table
      if (this.selectedRight) {
        delete this.selectedRight.selectedRow;
        if (this.selectedRight.id === item.id && toClear) {
          this.selectedRight = '';
          return;
        }
      }
      this.selectedRight = item || '';
      if (this.selectedRight) this.selectedRight.selectedRow = true;
    },

    leftContextMenu(item, event, index) {
      event.preventDefault();
      this.$refs.filterMenu.close();
      this.selectingLeft(item, false);
      this.$refs.menu.open(event, item);
    },

    openFilterMenu(name, list, event) {
      this.$refs.menu.close();
      const contextData = {
        colName: name,
        options: list,
        query: '',
      };
      this.$refs.filterMenu.open(event, contextData);
    },

    addOrRemoveElInArray(arr, item) {
      return arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item];
    },

    filterTable(opt, colName) {
      if (colName === 'name') this.filters.plan.selectedCommercialName = this.addOrRemoveElInArray(this.filters.plan.selectedCommercialName, opt);
      else if (colName === 'extId') this.filters.plan.selectedExternalId = this.addOrRemoveElInArray(this.filters.plan.selectedExternalId, opt);
      else if (colName === 'nameFact') this.filters.fact.selectedCommercialName = this.addOrRemoveElInArray(this.filters.fact.selectedCommercialName, opt);
      else if (colName === 'extIdFact') this.filters.fact.selectedExternalId = this.addOrRemoveElInArray(this.filters.fact.selectedExternalId, opt);
    },

    ifSelectedFilter(opt, colName) {
      if (colName === 'name') return this.filters.plan.selectedCommercialName.includes(opt);
      else if (colName === 'extId') return this.filters.plan.selectedExternalId.includes(opt);
      else if (colName === 'nameFact') return this.filters.fact.selectedCommercialName.includes(opt);
      else if (colName === 'extIdFact') return this.filters.fact.selectedExternalId.includes(opt);
      else return false;
    },

    resetFilter(colName) {
      this.$refs.filterMenu.close();
      if (colName === 'name') this.filters.plan.selectedCommercialName = [];
      else if (colName === 'extId') this.filters.plan.selectedExternalId = [];
      else if (colName === 'nameFact') this.filters.fact.selectedCommercialName = [];
      else if (colName === 'extIdFact') this.filters.fact.selectedExternalId = [];
    },

    openEditCommercialModal(item) {
      this.$refs.menu.close();
      this.isOpenModal = 'modal-commercial';
      this.modalName = 'edit';
      this.currentCommercial = item;
    },

    async unbindSpotsFromMenu() {
      if (this.selectedLeft && this.selectedLeft.fact_spot_id) {
        const coupleElem = this.bindFactGrps.fact.find((el) => el.spot_id === this.selectedLeft.spot_id);
        if (coupleElem) {
          this.selectedFactRow = coupleElem;
          this.unbindAllSimilarSpots = false;
          await this.unbindMatch();
        }
      }
      this.$refs.menu.close();
    },

    async unbindAllSimilarSpotsFunction() {
      if (this.selectedLeft && this.selectedLeft.fact_spot_id) {
        const coupleElem = this.bindFactGrps.fact.find((el) => el.spot_id === this.selectedLeft.spot_id);
        if (coupleElem) {
          this.selectedFactRow = coupleElem;
          this.unbindAllSimilarSpots = true;
          await this.unbindMatch();
        }
      }
      this.$refs.menu.close();
    },

    checkForUnmatched(item) {
      //if both spots are selected and are unmatched
      if (this.selectedLeft && item && !this.selectedLeft.fact_spot_id && !item.spot_id) {
        this.selectedFactRow = item;
        this.isNeedToForce = false;
        this.reconnectSimilar = false;
        this.rewriteExternalId = false;
        this.isExternalIdConflict = false;
        this.isLengthConflict = false;

        if (this.selectedLeft.external_id && this.selectedLeft.external_id !== item.external_id) {
          // if they have different ExtId
          this.isNeedToForce = true;
          this.isExternalIdConflict = true;
          if (Math.abs(this.selectedLeft.spot_duration - item.length) >= 2) this.isLengthConflict = true; // if also duration difference
          this.$bvModal.show('confirm-update-matched');
        } else if (Math.abs(this.selectedLeft.spot_duration - item.length) >= 2) {
          // if duration difference more than 2 sec.
          this.isLengthConflict = true;
          this.isNeedToForce = true;
          this.$bvModal.show('confirm-update-matched');
        } else if (!this.selectedLeft.external_id && item.external_id) {
          //if !extId + extId
          this.isNeedToForce = true;
          this.rewriteExternalId = true;
          this.reconnectSimilar = true;
          this.confirmMatch();
        } else this.confirmMatch(); // if extId = extId
      } else if (this.selectedLeft && item && this.selectedLeft.fact_spot_id === item.id) {
        this.selectedFactRow = item;
        this.unbindMatch();
      } else {
        if (item.spot_id) {
          // if click on right side
          // scroll to that element
          const rRow = document.querySelector(`#tableRight tr#tableRight__row_${item.id}`);
          const boundLeftY = rRow?.getBoundingClientRect()?.top; // get scroll position from page top for left
          const lRow = document.querySelector(`#tableLeft tr#tableLeft__row_${item.spot_id}`);
          if (lRow && boundLeftY) {
            const offsetpos = lRow.offsetTop; // get general position of right from table start
            const tableWrapper = document.getElementById('leftMatchingTable__wrapper');
            const tableLeftTop =
              tableWrapper.getBoundingClientRect().top +
              document.querySelector('#leftMatchingTable__wrapper > div.sides-headers').getBoundingClientRect().height;
            tableWrapper.scrollTo({ top: offsetpos - boundLeftY + tableLeftTop });
          }
        }
      }
    },

    rowLeftClass(item) {
      let classes = '';
      if (!item.fact_spot_id) {
        classes = 'not-matched';
      } else if (this.selectedRight && this.selectedRight.spot_id) {
        if (item.spot_id === this.selectedRight.spot_id) classes = 'couple';
      }

      if (item.selectedRow) {
        classes += ' b-table-row-selected table-active';
      }

      if (item.first_in_block) {
        classes += ' border border-top-w-2px border-danger border-right-0 border-left-0';
      }
      return classes;
    },
    rowRightClass(item) {
      let classes = '';
      if (!item.spot_id) {
        classes = 'not-matched';
      } else if (this.selectedLeft && this.selectedLeft.fact_spot_id) {
        if (item.spot_id === this.selectedLeft.spot_id) classes = 'couple';
      }
      if (item.selectedRow) {
        classes += ' b-table-row-selected table-active';
      }
      if (item.first_in_block) {
        classes += ' border border-top-w-2px border-danger border-right-0 border-left-0';
      }
      return classes;
    },

    syncScroll(item) {
      if (item.fact_spot_id) {
        // scroll to that element
        const leftRow = document.querySelector(`#tableLeft tr#tableLeft__row_${item.spot_id}`);
        const boundLeftY = leftRow?.getBoundingClientRect()?.top; // get scroll position from page top for left
        const rightRow = document.querySelector(`#tableRight tr#tableRight__row_${item.fact_spot_id}`);
        if (rightRow && boundLeftY) {
          const offsetpos = rightRow.offsetTop; // get general position of right from table start
          const tableWrapper = document.getElementById('rightMatchingTable__wrapper');
          const tableRightTop =
            tableWrapper.getBoundingClientRect().top +
            document.querySelector('#rightMatchingTable__wrapper > div.sides-headers').getBoundingClientRect().height;
          tableWrapper.scrollTo({ top: offsetpos - boundLeftY + tableRightTop });
        }
      }
    },

    async updateAndClearAfterAction() {
      await this.loadBindedGRPs();
      if (this.selectedLeft) delete this.selectedLeft.selectedRow;
      if (this.selectedRight) delete this.selectedRight.selectedRow;
      this.selectedLeft = '';
      this.selectedRight = '';
      this.selectedFactRow = '';
      this.unbindAllSimilarSpots = false;
    },

    async confirmMatch() {
      const formData = {
        spot_id: this.selectedLeft.spot_id,
        fact_grp_id: this.selectedFactRow.id,
        force: this.isNeedToForce ? true : undefined,
        ignoreExternalId: this.isNeedToForce && this.isExternalIdConflict ? true : undefined,
        time_delta: this.bindFactGrps.time_delta || undefined, // use delta from bind fact if exists
        rewriteExternalId: this.rewriteExternalId,
        reconnectSimilar: this.reconnectSimilar,
      };
      const leftSpot = this.bindFactGrps?.plan?.find((el) => el.spot_id === this.selectedLeft.spot_id);
      const rightSpot = this.bindFactGrps?.fact?.find((el) => el.id === this.selectedFactRow.id);
      const isEtxC = this.isExternalIdConflict;
      const isLC = this.isLengthConflict;
      await this.$store.dispatch('PUT_UPDATE_SPOT_FACT_GRP', {
        formData,
        handler: (res) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.spotsMatched'),
          });
          if (leftSpot && rightSpot && leftSpot.external_id && leftSpot.external_id === rightSpot.external_id && !isEtxC && !isLC) {
            const spot_id = res?.data?.data;
            if (spot_id) {
              leftSpot.fact_spot_id = this.selectedFactRow.id;
              rightSpot.spot_id = spot_id;
              if (leftSpot.position) this.checkForDifferentSpotPremiumPosition(leftSpot, rightSpot);
              //cleanup
              if (this.selectedLeft) delete this.selectedLeft.selectedRow;
              if (this.selectedRight) delete this.selectedRight.selectedRow;
              this.selectedLeft = '';
              this.selectedRight = '';
              this.selectedFactRow = '';
              this.unbindAllSimilarSpots = false;
            }
          } else this.updateAndClearAfterAction();
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
    },

    async unbindMatch() {
      this.requestExecuting = 'loading';
      const formData = {
        spot_id: this.selectedLeft.spot_id,
        fact_grp_id: this.selectedFactRow.id,
        unbindAllSimilarSpots: this.unbindAllSimilarSpots ? true : undefined,
      };
      await this.$store.dispatch('PUT_UNBIND_SPOT_FACT_GRP', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.spotsUnmatched'),
          });
          this.updateAndClearAfterAction();
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.requestExecuting = '';
    },

    async unbindAllSpots(withoutReload) {
      if (this.date && this.channel_id) {
        this.requestExecuting = 'loading';
        await this.$store.dispatch('DELETE_UNBIND_ALL_IN_DAY', {
          broadcastDay: this.date,
          channelId: this.channel_id,
          handler: (res) => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.allSpotsUnbound'),
            });
            if (!withoutReload) this.updateAndClearAfterAction();
          },
          handlerError: (errors) => {
            errorsHandler(errors, this.$notify);
          },
        });
        this.requestExecuting = '';
      }
      this.$bvModal.hide('confirm-unbind-all-spots');
    },

    async exportSpots() {
      this.exporting = true;
      await this.$store.dispatch('GET_EXPORT_IMPORT_FACT_SPOTS', {
        params: {
          format: 'xlsx',
          only_bound: this.isNotMatchedOnly ? 0 : 1,
          date: this.date,
          channel_id: this.channel_id,
          advertiser_id: this.selects.advertiser?.id || undefined,
          brand_id: this.selects.brand?.id || undefined,
          optional_fields: this.optionalFieldsList || undefined,
        },
        handler: (res) => {
          this.prepareAndDownloadFile(res);
        },
      });
      this.exporting = false;
    },

    async bindFactByExternalId() {
      if (!(this.selects.day && this.selects.channel?.id)) return;
      this.requestExecuting = 'loading';
      const formData = {
        broadcast_day: this.selects.day,
        channel_id: this.selects.channel.id,
        without_block_scope: !this.bindByBlockScope,
      };
      await this.$store.dispatch('POST_BIND_FACT_BY_MATCHING_ID', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.success'),
          });
          this.updateAndClearAfterAction();
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
      this.requestExecuting = '';
    },

    async bindFact() {
      if (!(this.selects.day && this.selects.channel?.id)) return;
      this.requestExecuting = 'loading';
      const formData = {
        broadcast_day: this.selects.day,
        channel_id: this.selects.channel.id,
      };
      await this.$store.dispatch('POST_BIND_FACT', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.dayBound'),
          });
          this.updateAndClearAfterAction();
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
      this.requestExecuting = '';
    },

    async fullFactRebind() {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('table.confirmFullRebind')}`, {
          title: this.$i18n.t('table.fullRebind'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-rebind-day',
        })
        .then((value) => {
          if (value) this.unbindAllSpots(true).then(() => this.bindFact());
        })
        .catch((err) => {});
    },

    checkForDifferentSpotPremiumPosition(spot, couple_fact_spot) {
      if (spot.position === '1F' && couple_fact_spot.slot_position !== 1) spot.not_equal_position = true;
      else if (spot.position === '2F' && couple_fact_spot.slot_position !== 2) spot.not_equal_position = true;
      else if (spot.position === '3F' && couple_fact_spot.slot_position !== 3) spot.not_equal_position = true;
      else if (spot.position === '3L' && couple_fact_spot.spot_count - couple_fact_spot.slot_position !== 2) spot.not_equal_position = true;
      else if (spot.position === '2L' && couple_fact_spot.spot_count - couple_fact_spot.slot_position !== 1) spot.not_equal_position = true;
      else if (spot.position === '1L' && couple_fact_spot.spot_count - couple_fact_spot.slot_position !== 0) spot.not_equal_position = true;
    },

    async showOrHideCol(item) {
      this.matchingFactLeftHiddenCols.includes(item)
        ? (this.matchingFactLeftHiddenCols = this.matchingFactLeftHiddenCols.filter((i) => i !== item))
        : (this.matchingFactLeftHiddenCols = [...this.matchingFactLeftHiddenCols, item]);
      localStorage.setItem('matchingFactLeftHiddenCols', this.matchingFactLeftHiddenCols); // save user shown\hidden columns
      this.$refs.matchingFactCustomizeColumns?.close(); // close context menu
      if (item !== 'id') await this.loadBindedGRPs(true); // reload to fetch new cols data
    },

    toggleFiltersRow() {
      this.showAdditionalFilter = !this.showAdditionalFilter;
      this.updateQuery();
    },

    async selectedAdvertiser(newValue) {
      if (this.selects.advertiser?.id) await this.loadBrandsList();
      else this.$store.commit('clearBrandsList');
      if (this.selects.brand?.advertiser_id !== this.selects.advertiser?.id) this.selects.brand = '';
      await this.loadBindedGRPs(true);
    },

    async loadBrandsList() {
      await this.$store.dispatch('GET_BRANDS', { 'filter[advertiser_id]': this.selects.advertiser?.id, per_page: 1000 });
    },

    changeCouple(value) {
      const channel = this.channels.find((c) => c.id === +value.channel_id);
      if (channel) this.loadBindedGRPs(true, { date: value.broadcast_day, channel: channel });
      if (this.$refs.queueMenu) this.$refs.queueMenu.close();
    },

    async checkStatusesInterval() {
      if (!(this.selects.day && this.selects.channel?.id)) return;
      //clear interval if all is ready
      if (
        this.bindFactGrps &&
        this.bindFactGrps.status === 1 &&
        (this.queueSelect.options.length === 0 || this.queueSelect.options.every((e) => e.status === 1)) &&
        this.checkStatuses
      ) {
        this.deleteImportChecker();
      }
      //check for opened channel+date if not imported yet
      if (this.bindFactGrps && this.bindFactGrps.status === 0) {
        await this.$store.dispatch('GET_CHECK_IMPORTS_FACT', {
          formData: {
            channel_id: this.selects.channel.id,
            dates: [this.selects.day],
          },
          handler: (res) => {
            if (res.data && res.data[this.selects.day]) this.loadBindedGRPs(true);
          },
        });
      }
      //check for queue list
      if (this.queueSelect.options.length === 0 || this.queueSelect.options.every((e) => e.status === 1)) return;
      const channelsDates = {};
      this.queueSelect.options.forEach((o) => {
        if (o.status === 1) return;
        if (!channelsDates[o.channel_id]) {
          channelsDates[o.channel_id] = [];
        }
        channelsDates[o.channel_id].push(o.broadcast_day);
      });
      for (const key in channelsDates) {
        await this.$store.dispatch('GET_CHECK_IMPORTS_FACT', {
          formData: {
            channel_id: +key,
            dates: channelsDates[key],
          },
          handler: (res) => {
            //update statuses for channel
            if (!res.data) return;
            for (const [keyRes, valueRes] of Object.entries(res.data)) {
              if (valueRes && valueRes.status === 1) {
                const t = this.queueSelect.options.find((opt) => opt.channel_id === +key && opt.broadcast_day === keyRes);
                if (t) t.status = 1;
              }
            }
          },
        });
      }
    },
  },
};
</script>

<style lang="sass">
.sides-headers
  height: 26px
#rightMatchingTable__wrapper
  h6 + div.custom-control
    min-height: auto
  #not-matched-checkbox
    &+.custom-control-label::before,
    &+.custom-control-label::after
      top: 2px

.table-matching-col
  max-height: calc(100vh - 120px)
  overflow-y: auto
  table
    tr:focus
      outline: 1px solid black
    td
      padding: 2px 0 2px 6px
      overflow: hidden
      text-overflow: ellipsis
    tr.not-matched
      background-color: rgba(255,0,0,0.2)
    tr.couple,
    tr.couple.table-active
      background-color: rgba(0,255,0,0.2) !important
    div.resizable
      overflow: auto
      overflow-x: hidden
      text-overflow: ellipsis

    div.resizable_name
      min-width: 150px
    div.resizable_date
      min-width: 78px
    div.resizable_time
      min-width: 58px
    div.resizable_position,
    div.resizable_external,
    div.resizable_duration
      min-width: 50px
    div.resizable_type
      min-width: 30px

    thead:hover div.resizable
      resize: horizontal

    tr > td:nth-child(1)
      max-width: 90px
      width: 90px
    tr > td:nth-child(2)
      max-width: 70px
      width: 70px
    tr > td:nth-child(4),
    tr > td:nth-child(5)
      max-width: 50px
      width: 50px

    tr > td[name="resizable_name"]
      max-width: 150px
      width: 150px
    tr > td.resizable_type
      max-width: 30px
      width: 30px

.with-queue .table-matching-col
  max-height: calc(100vh - 165px)

.table-matching-col.smaller
  max-height: calc(100vh - 165px)
  overflow-y: auto

.with-queue .table-matching-col.smaller
  max-height: calc(100vh - 210px)

.float-btn-marketing
  right: 16px
  bottom: 16px
  z-index: 5

#tableLeft > thead > tr >  th:nth-child(3),
#tableLeft > tbody > tr >  td:nth-child(3),
#tableRight > thead > tr >  th:nth-child(3),
#tableRight > tbody > tr >  td:nth-child(3)
  width: 30px
  max-width: 30px

@media (min-width: 1700px)
  .d-1700p-none
    display: none !important
  .d-1700p-block
    display: block !important
  .table-matching-col table
    div.resizable_name
      min-width: 200px
    tr > td[name="resizable_name"]
      max-width: 200px
      width: 200px

[data-color-scheme="dark"]
  .table-matching-col
    tr.couple,
    tr.couple.table-active
      background-color: rgba(0,255,0,0.2) !important
</style>
